<template>
  <div class="topbar">
    <header class="main-header " id="header">
      <nav class="navbar navbar-expand-md bg-dark navbar-dark">
        <!-- Brand -->
        <div class="navbar-placeholder">WILSON INTERIOR CONTRACTING</div>

        <!-- Navbar links -->
        <div class="collapse navbar-collapse" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a v-if="user" class="nav-link" href="#">{{user.username}}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" @click.prevent="logout">Logout</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';

export default {
  name: 'Topbar',
  data(){
    return {
      user: null,
    }
  },
  mounted(){
    this.getUser();
  },
  methods:{
    async getUser(){
      const currentUserId = window.localStorage.getItem('userId');
      const token = window.localStorage.getItem('loginToken');
      const loginInfo = {
        userId: currentUserId,
        token,
      };
      const user = await Common.getUserInfo(this.apiUrl, currentUserId, loginInfo);
      this.setUser(user);
      this.user = user;
    },
    async logout(){
      await this.startLogout();
      window.localStorage.clear();
      this.removeUser();
      this.$router.push({ name: 'Login'} );
    },
    ...mapActions(['startLogout', 'setUser', 'removeUser']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.topbar{
  position: sticky;
  top: 0;
  z-index: 50;
}
.logo{
  width: 40px;
}
.navbar-placeholder{
  flex: 1;
  color: white;
}
#collapsibleNavbar{
  justify-content: flex-end;
}
</style>
